.samples-overlay {
  #cboxClose {
    width: 35px;
    height: auto;
    background: transparent;
    color: #fff;
    font-size: 35px;
    margin: 5px;
    text-shadow: 0px 0px 5px #000;
  }
  #cboxContent {
    padding: 0;
    #samples-panel {
      margin: 1px;
      header {
        background: #000;
        color: #fff;
        h2 {
          padding: 0;
          margin: 1em;
          text-align: center;
          font-size: 25px;
        }
      }
      div.offer {
        padding: 20px 30px 0;
        h3 {
          text-align: center;
          border: 0;
          padding: 0;
        }
        div.remaining p {
          text-align: left;
          &.samples-panel__heading {
            font-size: 20px;
          }
          .samples-panel__promo-text {
            display: inline-block;
            &.large {
              display: none;
            }
            @include breakpoint($large-up) {
              display: none;
              &.large {
                display: inline-block;
              }
            }
          }
        }
        ul.product-list {
          border-top: 1px solid #000;
          margin: 0 20px;
          overflow: hidden;
          li.product {
            float: left;
            margin: 30px;
            width: 280px;
            min-height: 270px;
            div.product-img {
              text-align: center;
            }
            div.details {
              .product-name {
                font-size: 11px;
                line-height: 1.2em;
                height: 54px;
              }
              a.sample-select-button {
                display: block;
                width: auto;
                background: #ff4661;
              }
              a.sample-select-button.selected {
                background: #000;
              }
            }
          }
        }
      }
      div.add-button {
        margin: 10px 15px 15px 0px;
        float: right;
        input.btn {
          background: #ff4661;
          padding: 5px 20px;
          height: auto;
        }
      }
      a.continue-with-no-sample-btn {
        margin-top: 34px;
        display: block;
      }
    }
  }
}
