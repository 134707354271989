#delivery-info {
  select:disabled {
    border-color: $color-light-gray;
    color: $color-light-gray;
  }
}

#colorbox.sagawa-express-overlay {
  h3 {
    border-bottom: none;
    font-size: 20px;
    font-weight: 500;
  }
  p {
    margin-bottom: 15px;
  }
}

@include breakpoint($medium-up) {
  #shipping-address-display {
    .shipping-address-display__addresses {
      min-height: 120px;
    }
  }
}
