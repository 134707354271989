.review-panel {
  &.active &__title {
    @include breakpoint($small-down) {
      display: block;
    }
  }
  .payment-option {
    @include breakpoint($small-down) {
      width: 50%;
    }
    label {
      text-transform: capitalize;
    }
  }
  .checkout__subtitle {
    @include breakpoint($small-down) {
      @include h8;
      border-bottom: none;
      padding-bottom: 0;
      margin-top: 0;
      .change-address {
        font-family: $brandon-text;
      }
    }
  }
  .payment_form {
    width: 100%;
    @include swap_direction(margin, 0);
    .card-number {
      @include breakpoint($medium-up) {
        width: 253px;
      }
    }
    margin-left: 0;
    @include breakpoint($small-down) {
      padding: 0;
      margin-top: 5px;
    }
    .card-expiry-date {
      @include breakpoint($medium-up) {
        float: $ldirection;
      }
    }
    .card-type {
      @include breakpoint($medium-up) {
        margin-bottom: 20px;
      }
    }
    .card-cvn {
      @include breakpoint($medium-up) {
        float: $ldirection;
        width: 106px;
        margin-#{$ldirection}: 40px;
      }
    }
    .form-item {
      label {
        @include breakpoint($small-down) {
          margin-top: 8px;
          margin-bottom: 5px;
        }
      }
    }
    @include breakpoint($small-down) {
      label {
        display: block !important;
        visibility: visible;
        margin-bottom: 20px;
      }
      .expires-date {
        &__container {
          @include breakpoint($small-down) {
            margin-bottom: 0;
          }
        }
        &__month,
        &__year {
          display: inline-block;
          width: 49%;
          @include breakpoint($small-down) {
            margin-bottom: 0 !important;
            .selectbox {
              min-width: 100px;
            }
          }
        }
        &__year {
          float: right;
        }
      }
    }
  }
  .payment-address {
    margin-left: 0;
  }
  .change-address {
    margin-left: 10px;
  }
  .continue-button-wrapper {
    text-align: right;
  }
  .review-edit-content {
    @include breakpoint($small-down) {
      padding: 0 10px;
      .address {
        .select-address {
          .new-address {
            .form-item {
              margin-bottom: 5px;
            }
          }
        }
      }
      .checkout__panel-title {
        background: none !important;
        padding-left: 0;
      }
      .change-address {
        text-transform: capitalize;
      }
      .same-as-shipping {
        @include h22;
        font-size: 10px;
      }
    }
  }
  .new-address {
    label {
      display: block;
    }
    #qas {
      @include breakpoint($small-down) {
        margin-top: 10px;
      }
      @include breakpoint($medium-up) {
        margin-bottom: 10px;
      }
      .form-item {
        @include breakpoint($medium-up) {
          float: left;
          width: 48%;
          margin-right: 1%;
          &.manual-address {
            float: none;
            clear: both;
            width: 100%;
            padding-top: 12px;
          }
        }
      }
    }
    #manual-address-fields {
      @include clearfix;
      clear: both;
      padding-top: 12px;
    }
    .form-item {
      @include breakpoint($medium-up) {
        &.city,
        &.postal-code {
          // overrides for styles we don't want, coming from BB NA (actually, themes/bb_base, which only take NA addresses in mind)
          float: none;
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
