.order-summary {
  &-panel {
    @include breakpoint($small-down) {
      .checkout__content & {
        margin-top: 0;
      }
      .content {
        padding-left: 15px;
      }
      .total.label,
      .discount.label,
      .subtotal.label,
      .giftwrap.label,
      .shipping.label,
      .tax.label {
        font-family: $brandon-text;
        text-transform: none;
      }
    }
  }
  &__total {
    &-value {
      font-weight: normal;
    }
    &-label,
    &-value {
      @include breakpoint($small-down) {
        border-top: $cart-line;
      }
    }
  }
  &__content {
    .shipping_price.label {
      width: 65%;
    }
    .shipping_price.value {
      width: 35%;
    }
    .checkout__content & {
      .shipping_price.label,
      .shipping_price.value {
        float: left;
        text-align: right;
      }
    }
    .checkout__sidebar & {
      .shipping_price.label,
      .shipping_price.value {
        float: left;
      }
      .shipping_price.label {
        text-align: left;
      }
      .shipping_price.value {
        text-align: right;
        padding-right: 0;
      }
      .shipping {
        display: none;
      }
    }
  }
}

.viewcart-buttons-panel {
  @include breakpoint($small-down) {
    /// Paypal button
    .paypal-checkout {
      display: inline-block;
      float: left;
      text-align: left;
    }
    .or {
      display: inline-block;
      margin: 7px 10px;
      float: left;
      font-size: 11px;
    }
    a.continue-shopping {
      position: absolute;
      bottom: 0;
    }
    a.continue-checkout {
      width: auto;
    }
    .continue-shopping {
      border-width: 0px;
      padding: 0px;
      border-style: none;
      text-align: left;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 1px;
      text-decoration: underline;
      font-size: 14px;
      font-family: $brandon-text;
    }
    .content {
      padding: 0 0 10px;
    }
    .continue-checkout {
      padding: 0.5em 0.67em !important;
    }
    .paypal-checkout {
      width: 45%;
    }
  }
  @include breakpoint($medium-up) {
    .or {
      margin: 0 10px;
    }
  }
}

.viewcart-buttons-panel {
  .content {
    padding-right: 0px;
  }
  .paypal-checkout {
    margin-left: 0;
    height: 45px;
    &.disabled {
      cursor: context-menu;
      text-decoration: none;
      text-align: right !important;
      margin-right: 20px;
    }
    span.paypal-message {
      position: relative;
      display: block;
      text-align: center;
      color: #ababab;
      font-weight: bold;
      width: 157px;
      @include breakpoint($small-down) {
        margin: 0px auto;
        line-height: 14px;
        width: 100% !important;
        text-align: right;
      }
    }
  }
  .continue-buttons {
    overflow: inherit;
    float: none;
  }
  border: 0;
}
