.change-password {
  .profile-password-update {
    &__action {
      .form-submit {
        &:disabled,
        &.disabled {
          color: $color-light-gray;
          background-color: $color-white;
          cursor: not-allowed;
          border: 1px solid $color-gray-light;
        }
      }
    }
  }
  .password-field {
    &__info {
      @include breakpoint($medium-up) {
        #{$ldirection}: 100%;
        top: 33%;
        width: 190px;
        margin-#{$ldirection}: 3%;
      }
      &-reset {
        @include breakpoint($medium-up) {
          top: 38%;
          #{$ldirection}: 32%;
          width: 190px;
        }
      }
      &-confirm {
        margin-#{$ldirection}: 2%;
        @include breakpoint($medium-up) {
          width: 190px;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      &:before {
        @include breakpoint($medium-up) {
          #{$ldirection}: auto;
          #{$rdirection}: 100%;
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
    &__rules {
      font-size: 9px;
      @include breakpoint($medium-up) {
        font-size: 12px;
      }
    }
  }
  &-gnav {
    .cms-password-field {
      &__info {
        &:before {
          top: 36%;
        }
      }
    }
  }
}
