.mantle-form-options-field {
  &__header {
    .style--bare {
      @include breakpoint($small-down) {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  &__subheader {
    .style--bare {
      @include breakpoint($small-down) {
        font-size: 15px;
      }
    }
  }
  &__helper-text {
    @include breakpoint($small-down) {
      font-size: 14px;
    }
    .overlay-header {
      @include breakpoint($small-down) {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .overlay-subheader {
      @include breakpoint($small-down) {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}

// Formula Product Name
.small-left-image-box .label {
  font-weight: bold;
}
