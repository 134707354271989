.shipping-address-display {
  margin: 0 0 0 20px;
  @include breakpoint($medium-up) {
    float: left;
    margin: 0 0 0 20px;
  }
}

.gift-options-display {
  #gift-options-h {
    display: inline-block;
    @include breakpoint($small-down) {
      width: 36%;
    }
  }
  .gift-wrap-edit-message {
    margin-top: 10px;
  }
  @include breakpoint($medium-up) {
    float: left;
    width: auto;
    margin: 0px 20px 20px 0px;
  }
}

.shipping-address-display,
.gift-options-display,
.billing-address-display,
.delivery-options-display {
  @include breakpoint($small-down) {
    margin: 20px 0px 0px 20px;
  }
}

.delivery-options-display {
  float: left;
  @include breakpoint($small-down) {
    margin: 20px 20px 0 20px;
  }
}

.shipping-address-display__title {
  margin-bottom: 10px;
}

.shipping-address-display,
.billing-address-display {
  margin-top: 1em;
}

.shipping-edit-address-content__title {
  display: block;
}
