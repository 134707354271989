.spp-product-layout {
  .spp-product-layout__content-item-heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 2.5;
    @include breakpoint($medium-up) {
      font-size: 48px;
      line-height: 0.89;
    }
    @include breakpoint($large-up) {
      font-size: 15px;
      font-weight: 700;
    }
    b {
      display: none;
      @include breakpoint($large-up) {
        display: block;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 0.89;
      }
    }
  }
}

.responsive-tabs--powerreviews {
  .resp-tab-item {
    display: none;
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-family: $brandon-text;
  }
}

.lp_buttons_area {
  .lp_submit_button,
  .lp_confirm_button,
  .lp_close_survey_button {
    &:hover {
      background: $color-black !important;
    }
  }
}

.product-foundation-vto {
  &__btn-container {
    .see-my-perfect {
      margin-#{$rdirection}: -6%;
    }
  }
  &__container {
    ul.shade-list-vto {
      .swatches--single {
        a.swatch {
          background-color: $color-white;
        }
      }
    }
  }
}

.cart_overlay .engraving__minibag {
  display: none;
}

#csr_header {
  .csr_table {
    table-layout: auto;
    td {
      border-bottom: 0;
    }
  }
}
