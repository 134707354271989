.samples {
  &-panel {
    .product {
      .sample-select-button {
        display: inline-block;
        position: static;
        width: 80%;
        cursor: pointer;
        .checkout__sidebar & {
          @include button--secondary;
          &.selected {
            @include button;
          }
        }
      }
    }
    .product-subhead {
      display: block;
    }
    footer {
      display: none;
    }
  }
  &-page &-buttons {
    .continue-checkout {
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  &-page {
    .samples {
      margin-top: 10px;
      .product {
        .details {
          .product-name {
            min-height: 70px;
          }
          .product-subhead {
            min-height: 100px;
          }
        }
      }
    }
    .samples-buttons {
      .add-button {
        padding-top: 16px;
        @include breakpoint($medium-up) {
          padding-top: 6px;
          margin-right: 10px;
          input {
            height: 40px !important;
          }
        }
      }
      @include breakpoint($medium-up) {
        .return-link {
          margin-right: 10px;
        }
      }
      &.bottom {
        padding-top: 20px;
        display: block;
      }
      @include breakpoint($small-down) {
        display: none;
      }
    }
    .viewcart-buttons-panel {
      .edit-cart {
        margin-bottom: 5px;
        a {
          border-style: solid;
          border-width: 2px;
          padding: 10px;
          text-decoration: none;
          border: 2px solid #000000;
          width: 100%;
          display: block;
          text-align: center;
          color: #000000;
        }
      }
      @include breakpoint($small-down) {
        a.continue-checkout {
          width: 100%;
          padding: 14px !important;
        }
      }
    }
  }
}

.samples,
.favorites,
.past-purchases {
  &-panel {
    .skus {
      .checkout__sidebar & {
        margin: 8px 0;
        label {
          clear: none;
        }
      }
    }
    .product-name,
    .shade,
    .size {
      .checkout__sidebar & {
        font-size: 11px;
      }
    }
    .shade,
    .size {
      .checkout__sidebar & {
      }
    }
    .product-list {
      margin: 10px !important;
      .checkout__sidebar & {
        overflow: hidden;
        min-height: inherit;
      }
      .cycle-slide {
        width: 100%;
      }
      .product {
        padding: 20px 0 0 0 !important;
        margin: 0 8px !important;
      }
    }
    .dot-pager {
      display: none;
    }
    .prev-next {
      #cycle-prev {
        float: left;
      }
      #cycle-next {
        float: right;
      }
    }
  }
}

.favorites,
.past-purchases {
  &-panel {
    .details {
      .checkout__sidebar & {
        float: left;
        @include breakpoint($large-up) {
          width: 68%;
        }
      }
    }
    .shade {
      .checkout__sidebar & {
        padding-top: 3px;
      }
    }
    .product-name {
      &,
      & a {
        color: black;
        text-decoration: none;
      }
    }
    input[type='submit'] {
      .checkout__sidebar & {
        &,
        &.btn {
          width: 80%;
          &:hover {
            background-color: $color-black;
          }
        }
      }
    }
  }
}

.samples-overlay #samples-panel .prev-next {
  display: none !important;
}

.samples-overlay #samples-panel.loading {
  @include loading;
  opacity: 0.3;
}

.samples-page #checkout_samples.loading {
  @include loading;
  opacity: 0.3;
  position: relative;
}
/* toggle heading */
.remaining {
  .samples-panel__promo-text {
    display: inline-block;
    &.large {
      display: none;
    }
    @include breakpoint($large-up) {
      display: none;
      &.large {
        display: inline-block;
      }
    }
  }
}
