// offers
.offer-code-panel {
  input[type='submit'].btn {
    .checkout & {
      @include breakpoint($small-down) {
        background-color: #ff4661;
      }
    }
  }
  input[type='text'] {
    @include breakpoint($medium-up) {
      width: 75%;
      text-transform: none !important;
    }
    @include breakpoint($small-down) {
      text-transform: none !important;
    }
  }
}

.promo-panel {
  img {
    height: 100%;
  }
}

.checkout__sidebar .tab-pane.active #samples-panel footer {
  display: none;
}
