.account__return-user,
.account__new-account {
  input[type='email'] {
    width: 100%;
    display: block;
    margin-top: 10px;
  }
}

#registration {
  .newsletter-info__text {
    padding: 0 20px;
    margin: 0;
  }
  .required_fields {
    padding: 5px;
  }
  .terms_required_fields {
    margin: 20px;
  }
  .address_static_block {
    margin-left: 10px;
  }
  .when_is_your_birthday {
    label {
      margin-left: 10px;
    }
  }
}

.address_lookup_submit {
  margin: 10px 0;
}

.lookup_submit_note {
  margin: 10px;
  position: absolute;
  @include breakpoint($small-down) {
    display: block;
    position: inherit;
    margin: 10px 0;
  }
}

.order-details__item {
  h4 {
    font-weight: bold;
  }
}

.order_total {
  float: left;
  margin-right: 23px;
}

input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before {
  float: left;
}

.address-form__item {
  label.error {
    border: none;
  }
}

#add_address,
#edit_address {
  .address-header {
    h1 {
      float: left;
    }
    .grey_button {
      float: right;
      margin-right: 10px;
    }
  }
  .required_field_notice {
    margin-left: 10px;
  }
  #profile-postal {
    input {
      width: 47%;
      margin-right: 10px;
    }
  }
  .lookup_submit_note {
    float: left;
    position: relative;
    margin: 10px 0 0 0;
  }
  .address_lookup_submit {
    width: 47.5%;
    display: inline;
    font-size: 11px;
    padding: 12px;
  }
  .grey_button {
    margin-left: 10px;
    padding: 9px;
    text-decoration: none;
  }
}

.upload-file__imposter {
  color: $color-black;
  padding: 5px;
}

.favorites {
  .my-lists__list-actions {
    a.js-share {
      display: none;
    }
  }
}

.address-book-page__content {
  section.address-book {
    padding: 0px 10px;
    margin: 0px;
    width: auto;
    .messages {
      background-color: #fff;
      border: none;
      padding: 10px 0px;
    }
    .address-book__others {
      .address-item__controls {
        .js-default-address {
          margin-bottom: 10px;
        }
      }
    }
    .address-book__content a {
      width: 100%;
    }
  }
  .payment-info {
    padding: 0px 10px;
    margin: 0px;
    width: auto;
    .payment-info-book {
      &__header {
        margin: 0 0 10px 0;
        padding: 14px 0;
        border-bottom: 1px solid $color-black;
        font-size: 1.5em;
        letter-spacing: 0.15px;
        font-family: $brandon-text;
      }
    }
    .payment-item {
      &__details {
        border-bottom: none;
      }
    }
  }
  .address-book__header {
    text-indent: 0px;
  }
  .payment-info__header {
    text-indent: 0px;
  }
}

.shipping-billing {
  margin-bottom: 10px;
  .breadcrumb {
    padding: 10px;
  }
  #address {
    input.form-submit {
      float: left;
      margin: 0px 10px;
    }
    a.control-btns {
      float: right;
      margin-right: 10px;
    }
  }
}

.account__section {
  margin: 10px auto !important;
  .profile-page {
    padding: 0px 10px;
    .profile-page__head {
      a {
        margin-bottom: 10px;
        display: block;
      }
    }
    h3 {
      text-indent: 0px;
    }
    .optional-info {
      .optional-info__item {
        width: 100%;
        margin: 0px;
        span {
          display: block;
        }
      }
    }
    &__content {
      .profile-info {
        &__text {
          margin: 0 0 20px 0;
          padding-bottom: 14px;
          border-bottom: 1px solid $color-black;
        }
      }
    }
  }
  a.address_back_link {
    display: block;
    padding: 10px;
  }
}

.order-details-page__content {
  h2.order-details-page__header {
    margin: 10px 0 20px 0;
  }
  a.order-details-page__link {
    margin-bottom: 10px;
    display: block;
  }
  .cart-item__thumb {
    height: auto;
  }
  .cart-item__desc-info {
    .sub_line {
      a {
        color: #000;
        text-decoration: none;
      }
    }
    .cart-item__monogram-text {
      font-size: 14px;
    }
  }
  .cart-item__qty-label {
    display: inline-block;
  }
  .cart-item__total {
    float: right;
    line-height: 36px;
  }
  .cart-item__desc,
  .cart-item .error {
    width: 100%;
  }
  .product-info__spp-status {
    float: left;
  }
}

.profile-pic-main {
  #profile-change-photo {
    display: block;
    float: none;
  }
  #profile-delete-photo {
    display: block;
    float: none;
  }
}

.past-purchases-page {
  a.order_history_back_link {
    padding: 10px;
    display: block;
  }
}

.favorites-page {
  a.favorites_back_link {
    display: block;
    padding: 10px;
  }
}

.favorites {
  .product-info__spp-status {
    margin-top: 10px;
    a.product-info__link:last-child {
      display: block;
    }
  }
}

#past_purchases {
  .past-purchases__content {
    margin: 10px 0;
  }
}

.password-sent-page {
  .sent-info {
    &__text {
      margin: 10px 0px;
    }
  }
}

.password-reset-page {
  .password-reset__item {
    margin: 10px;
    input {
      width: 100%;
    }
  }
}

.account__return-user {
  .return-user__text {
    text-align: center;
  }
  .forgot_pwd {
    margin-bottom: 20px;
    &_text {
      display: inline;
    }
  }
}

.profile-pic-upload {
  &__help {
    padding: 0 14px;
  }
  .upload-file__value {
    left: 145px;
  }
}

.past-purchases {
  &__content {
    &.order_content {
      @include breakpoint($small-down) {
        padding: 0px;
      }
    }
  }
}

.address-form-container {
  .lookup_results_container {
    float: left;
    text-align: left;
    margin-top: 10px;
  }
}

.promotions-container {
  .newsletter-info__fieldset {
    a {
      color: $color-pink;
    }
  }
  .sms-promotions {
    a {
      color: $color-pink;
    }
  }
}

.favorites-page {
  .favorites__instruction-step {
    .favorites__instruction-step-desc {
      width: 100%;
      padding: 0 10px;
    }
    .favorites__instruction-step-title {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.add-payment-page {
  margin: 20px;
  .payment-form {
    &__item {
      input {
        width: 100%;
      }
    }
  }
  &__header {
    line-height: 30px;
  }
}

.account-replenishment {
  a.replenishment_back_link {
    padding: 10px;
    display: block;
  }
  .replenishment-page {
    &__header-container {
      @include breakpoint($small-down) {
        text-align: center;
      }
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
        margin: 0 auto 20px auto;
        text-indent: 0;
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: $account-indent;
        margin: 4px 0 17px 0;
        padding-bottom: 16px;
        border-bottom: 2px solid $color-black;
      }
    }
  }
}

.profile-page {
  .profile-page__content {
    margin: 0px;
  }
}

form#address.adpl {
  .last_name_container,
  .first_name_container,
  .last_name_alt_container,
  .first_name_alt_container,
  .state_container,
  .city_container,
  .address_container,
  .phone_container {
    display: inline-block;
    width: 48.5%;
  }
  .city_container {
    input {
      width: 100%;
    }
  }
  select {
    height: 3em;
    &.input_state {
      margin-bottom: 0;
    }
  }
  .address-form__item {
    padding: 10px 5px 10px 5px;
  }
  #profile-postal {
    label {
      position: absolute;
      margin-top: -38px;
    }
  }
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(data-required) attr(placeholder);
  }
  input[type='email'] + label,
  input[type='tel'] + label,
  input[type='password'] + label,
  input[type='text'] + label {
    font-size: 0.85em;
    line-height: 2.5em;
    letter-spacing: 0px;
  }
}

.adpl form#payment {
  .payment-form__item {
    width: 100%;
    display: inline-block;
  }
}

form#profile_preferences.adpl {
  .profile-info__item {
    width: 97%;
    padding-right: 2px;
    label {
      font-size: 0.88em;
      letter-spacing: 0px;
    }
    input:focus + label:before,
    input.active + label:before,
    input.js-label-mode + label:before {
      font-size: 1.1em;
    }
  }
  .optional-info__item {
    width: 33%;
    display: inline-block;
  }
}

form#profile_preferences.adpl {
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(data-required) attr(placeholder);
    margin: 0 calc(0.5em - -2px);
  }
}

.site-email-sms-signup {
  padding: 0;
  margin: 0 auto;
  max-width: 1280px;
  &__header {
    padding: 30px 10px 0;
    background-image: url('/media/export/cms/loyalty/marketing_mobile_banner.jpg');
    background-size: cover;
    font-family: $brandon-text;
    h1 {
      font-family: $brandon-text-bold;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -2px;
    }
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.2em;
      margin-bottom: 10px;
    }
  }
  &__content {
    padding: 0 10px;
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.35em;
      margin-bottom: 10px;
      text-align: center;
    }
    h1 {
      font-family: $brandon-text-bold;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -2px;
      text-align: center;
    }
    .site-email-sms-signup__form {
      padding: 0 10px;
      .site-email-sms-signup {
        &__field {
          height: 50px;
          border-color: $color-light-gray;
        }
        &__birthday {
          height: auto;
          text-align: $left;
          &-label {
            width: 100%;
            float: none;
            display: block;
          }
          input[type='text'] {
            width: 104px;
            margin: 0;
            height: 50px;
            border-color: $color-light-gray;
          }
        }
        &__submit {
          width: 187px !important;
          height: 62px;
        }
        &__postal-code {
          input[type='text'] {
            margin: 10px;
            width: 70%;
            height: 50px;
            border-color: $color-light-gray;
          }
        }
      }
    }
    &__terms {
      padding: 0 20px;
    }
  }
  &__content-2 {
    margin-top: 90px;
    .site-email-sms-signup {
      &__img {
        background-color: $color-whisper-shade;
        height: 300px;
        margin: 40px 0;
      }
      &__btn {
        text-align: center;
        margin: 40px 0;
        .btn {
          width: 187px;
          height: 62px;
          line-height: 2.3;
          padding: 15px;
          text-decoration: none;
          background: $color-black;
          color: $color-white;
          display: inline-block;
          text-align: center;
        }
      }
    }
    h1,
    h2,
    h5 {
      margin: 0 auto;
    }
    h2 {
      text-align: center;
      padding-top: 20px;
    }
    h1 {
      font-size: 36px;
      font-family: normal;
      font-weight: 400;
      line-height: 48px;
    }
  }
}

.site-email-sms-thank-you {
  padding: 0;
  margin: 0 auto;
  &__header {
    background: none;
    h1 {
      margin: 0 auto;
      font-family: $brandon-text;
      font-weight: 800;
      padding-bottom: 10px;
      letter-spacing: 0;
    }
  }
  &__content-2 {
    margin-top: 10px;
    h1 {
      font-family: normal;
      font-size: 36px;
      letter-spacing: -2px;
      text-align: center;
      line-height: 48px;
    }
    h2 {
      text-align: center;
      padding-top: 20px;
    }
    h1,
    h2,
    h5 {
      margin: 0 auto;
    }
    h5 {
      margin-top: 20px;
      font-size: 1.2em;
      line-height: 1.2em;
      margin-bottom: 10px;
      text-align: center;
    }
    .site-email-sms-signup {
      &__img {
        background-color: $color-whisper-shade;
        height: 120px;
        margin: 15px;
      }
      &__btn {
        text-align: center;
        margin: 40px 0;
        .btn {
          width: 187px;
          height: 62px;
          line-height: 2.3;
          padding: 15px;
          text-decoration: none;
          background: $color-black;
          color: $color-white;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}
