.product {
  &__inventory-status {
    .preorder-noshop {
      font-size: 11px;
      text-align: $ldirection;
    }
  }
  &__flag_preorder {
    .product {
      &__shade-preorder {
        display: none;
      }
      &__shade-enable {
        display: inline-block;
      }
    }
  }
  &-grid__item {
    .product-thumb {
      .product {
        &__soldout-preorder {
          border: none;
          background-color: $color-light-gray;
          color: $color-white;
        }
      }
    }
  }
}

.preorder_spp_container {
  .pr-snippet-read-and-write,
  .pr-rd-no-reviews {
    .pr-snippet-write-review-link {
      pointer-events: none;
      opacity: 0.6;
      cursor: default;
    }
  }
  .preorder_sold_out {
    display: none;
  }
  .spp-mobile-page {
    .shaded_details {
      .product-palette {
        .select-cta {
          .preorder_sold_out {
            display: none;
          }
        }
      }
    }
  }
}

.checkout {
  &__sidebar {
    .preorder-panel {
      .cart-items {
        .preorder-text {
          &__content {
            padding-left: 28%;
            float: $ldirection;
          }
        }
      }
    }
  }
}

.preorder-panel {
  margin-top: 20px;
  .cart-item__qty {
    select {
      background-position: 36px 16px;
    }
  }
  &__viewcart-text {
    margin-top: 5px;
  }
}

.cart_overlay {
  .cart-product-preorder {
    text-align: $ldirection;
    font-size: 12px;
  }
}
