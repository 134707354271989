a.selectBox {
  &.error {
    border: 1px solid $color-red;
  }
}

.lp_pagination-area {
  .lp_buttons_area {
    .lp_next_button {
      background: $color-black !important;
    }
  }
}
