/// Mixins, Placeholders and Variables
@mixin checkout-sidebar-panel-border {
  margin-top: 6px;
  border: 5px solid $color-gray-light-alt;
  padding: 15px 15px 20px 15px;
  background: $color-white;
  &__title {
    margin-top: 34px;
  }
}

@mixin checkout-mobile-header {
  background-color: $color-gray-light-alt;
  padding: 10px 15px;
  border: none !important;
  text-indent: 0;
  font-size: 16px;
  text-align: left;
  line-height: 22px;
}

@mixin payment-panel {
  .payment-edit-content {
    margin: 0;
    .form-item,
    .form_element {
      @include breakpoint($medium-up) {
        width: 45%;
      }
      &.cvv {
        width: 100%;
        @include swap_direction (margin, 10px 20px 10px 0);
        .cv_number_field {
          @include breakpoint($medium-up) {
            width: 45%;
          }
          float: none;
          margin-#{$rdirection}: 0;
        }
      }
      &.expiry {
        select {
          width: 46.8%;
          @include breakpoint($small-down) {
            width: 47.8%;
          }
        }
        label {
          width: 100%;
        }
      }
    }
    .payment_gmo .continue-button-wrapper {
      clear: $ldirection;;
      margin-top: 10px;
      @include breakpoint($small-down) {
        margin: 20px 0 0;
      }
    }
    .continue-button-wrapper {
      &.rakuten {
        input {
          @include breakpoint($small-down) {
            width: 100%;
          }
          width: 270px;
          background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
          border: none;
          color: transparent;
        }
      }
    }
    .payment-container {
      label {
        width: 45%;
        vertical-align: top;
        display: inline-block !important;
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
      margin-bottom: 10px;
      .payment-details {
        margin-top: 10px;
      }
      input[type="radio"] {
        display: none;
      }
      &__option {
        &--rakuten ~ label {
          width: 100%;
          @include breakpoint($medium-up) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .payment-form .security-code {
    display: inline-block;
    margin-#{$ldirection}: 0;
  }
  .payment-form {
    @include breakpoint($small-down) {
      input[type='text'] + label {
        margin-top: calc(-3em - 1px);
      }
      .cv_number_field {
        width: 100% !important;
      }
    }
    input[type="checkbox"] {
      display: none;
      ~ label:before,
      ~ .label:before {
        float: none;
      }
    }
    .card-number {
      margin-top: 20px;
    }
  }
}


@mixin checkout-label-and-checkbox-form-item {
  input[type='checkbox'] ~ label,
  input[type='checkbox'] ~ .label {
    display: block;
    padding-left: 20px;
    position: relative;
  }
  input[type='checkbox'] ~ label:before,
  input[type='checkbox'] ~ .label:before {
    height: 15px;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 3px;
    width: 15px;
  }
}

%checkout-label-and-checkbox-form-item {
  @include checkout-label-and-checkbox-form-item;
}

// Fixing the radio buttons being vertically misaligned
label:before {
  margin-top: -3px;
}

.review-panel {
  @include payment-panel;
  &__title {
    @include checkout-section-header;
  }
  .checkbox {
    display: block; // bb_base/scss makes the .checkbox div, which contains for the uk the terms stuff needed for
    // guest/anon. checkout, invisible, so we're "redisplaying" it here
  }
  .review-edit-content {
    .payment_method-container {
      #checkout_payment {
        .form-item.card-cvn {
          #security-code {
            display: block;
          }
        }
      }
    }
    #checkout_saved_payment {
      .form-item {
        #security-code {
          display: block;
        }
      }
    }
  }
}

#checkout-header {
  .shopping-region {
    float: right;
  }
  .site-logo__image {
    text-indent: 0;
  }
}

.viewcart {
  .engraving-wrapper {
    .engraving-view-title {
      margin: 0 10px;
    }
    .engraving-view-text,
    .engraving-view-motif,
    .engraving-view-control {
      margin: 20px 5%;
    }
  }
  .engraving-wrapper.mobile {
    width: 100%;
    .engraving-view-control {
      text-align: center;
      font-weight: bold;
    }
    .engraving-toggle-edit {
      margin-left: 15px;
      display: inline-block;
    }
  }
  &-panel {
    .checkout__sidebar & {
      padding: 13px 19px;
    }
    &__title {
      .checkout__content & {
        border-bottom: 2px solid $color-black;
      }
      .checkout__sidebar & {
        @include breakpoint($small-down) {
        }
        @include breakpoint($medium-up) {
          display: inline-block !important;
          padding-bottom: 0;
        }
        text-indent: 0;
        border-bottom: none;
        margin-bottom: 0;
        margin-top: 0;
        & #shopping-bag-title-edit {
          border-bottom: none;
          @include breakpoint($small-down) {
            font-size: 16px;
          }
        }
      }
    }
    #shopping-bag-edit {
      .checkout .checkout__sidebar & {
        @include breakpoint($medium-up) {
          bottom: 10px;
        }
        @include breakpoint($small-down) {
          display: block;
          right: 7px;
        }
      }
    }
    .viewcart-buttons-panel {
      .go-shopping {
        display: none;
        @include breakpoint($small-down) {
          display: block;
        }
      }
    }
  }
  &-header {
    .checkout__sidebar & {
      padding-bottom: 0;
      min-height: 35px;
      padding-top: 3px;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-black;
      }
      @include breakpoint($small-down) {
        border-bottom: none;
        .edit {
          display: none;
        }
      }
    }
  }
  // cart items header
  &-buttons {
    &-panel {
      @media (min-width: 768px) {
        margin-top: 20px;
        padding-top: 20px;
      }
      border-top: 1px solid $color-gray;
      .content {
        padding-right: 20px;
      }
      .continue-buttons {
        height: 0px;
      }
      .continue-checkout-btns {
        display: inline-block;
        a {
          float: left;
        }
        .continue-checkout {
          margin-right: 5px;
        }
        .choose-samples-checkout {
          display: block !important;
          visibility: visible;
          background-color: #ff4661;
          color: #ffffff;
        }
      }
    }
  }
  @include breakpoint($small-down) {
    a.more-info {
      background: url(../../../../../media/export/images/click_collect/more-info-icon.png) no-repeat right center;
      padding-right: 15px;
    }
  }
  @include breakpoint($medium-up) {
    .shipmethod-panel {
      .content {
        padding-right: 20px;
      }
      &__title {
        padding-right: 20px;
        text-align: right;
        border-bottom: none;
      }
      .ship-method-group-label {
        width: 48%;
        text-align: right;
        display: inline-block;
        padding-bottom: 20px;
        @include h24;
        &.more_info_label {
          width: 64%;
          a.more-info {
            margin-left: 20px;
            text-decoration: none;
            font-size: 12px;
            background: url(../../../../../media/export/images/click_collect/more-info-icon.png) no-repeat right center;
            padding-right: 15px;
            span {
              border-bottom: 2px solid;
              padding-bottom: 5px;
            }
          }
        }
        &.minimise {
          width: 35%;
        }
      }
      .home-form-item {
        width: 75%;
        margin-left: 25%;
      }
      .form-item {
        text-align: right;
        select,
        a {
          position: relative;
          left: 44%;
          width: 56%;
          padding-right: 30px;
        }
      }
      .date22-form-item {
        margin-left: 40%;
        width: 60%;
        select {
          width: 74%;
        }
      }
      .delivery-note {
        text-align: right;
        margin-top: 12px;
      }
    }
    .donation-panel {
      .content {
        padding-right: 20px;
      }
      &__title {
        padding-top: 17px;
        padding-right: 20px;
        text-align: right;
        border-bottom: none;
        margin-bottom: 0px;
      }
    }
  }
  .checkout__sidebar {
    .tab-switcher {
      .tab-content {
        #past-purchases-pane {
          .past-purchases-panel {
            .content {
              .product-list {
                min-height: inherit;
              }
            }
          }
        }
        #favorites-pane {
          .favorites-panel {
            .content {
              .favs {
                .product-list {
                  min-height: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
  #recommended-products-panel {
    margin-top: 45px;
    .content {
      .recommended-product-items {
        .recommended-item {
          .quickshop {
            display: block;
          }
          .description {
            .info {
              .produce_subname {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .bogo-offer {
    .cart-item {
      &__replenishment,
      &__remove-form,
      &__add-to-favorites {
        display: none;
      }
    }
  }
}

.checkout {
  &.pg_wrapper {
    // get different margin settings, for small browser sizes, for checkout's content area
    @include breakpoint($small-down) {
      padding-top: 0;
      margin-left: 15px;
      margin-right: 5px;
    }
  }
  label {
    @include breakpoint($small-down) {
      font-size: 12px !important;
      line-height: 2.5em !important;
      letter-spacing: 0;
    }
  }
  .loading {
    position: static;
    @include opacity(1);
    * {
      @include opacity(0.8);
    }
  }
  &__header {
    &-item-count {
      &:before {
        content: '';
      }
      .checkout__content & {
        @include breakpoint($small-down) {
          display: none;
        }
        display: block;
        position: absolute;
        bottom: 0;
        right: 20px;
        text-align: right;
        padding-bottom: 15px;
        min-width: 100px;
        @include h8;
        font-size: 14px;
      }
      .checkout__sidebar & {
        text-align: left;
        display: inline-block;
        margin-left: 30px;
        @include breakpoint($small-down) {
          display: none;
        }
      }
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      .viewcart & {
        width: 100% - $checkout-sidebar-width; // override the viewcart width, coming from bb_base, narrowing it for the UK, to accomodate the wider sidebar below (per the mocks given)
      }
      .offer-code-panel {
        margin-top: 6px;
        .content {
          p {
            margin: 0px 0px 15px 0px;
          }
          #offer_code {
            margin-bottom: 15px;
          }
          border-bottom: 1px solid $color-gray;
        }
      }
      #delivery-options-panel {
        p {
          margin: 20px 0px 20px 0px;
        }
        h2 {
          margin: 20px 0px 5px 0px;
        }
      }
    }
  }
  &__panel-title,
  &__content &__panel-title {
    @include breakpoint($small-down) {
      @include checkout-mobile-header;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 18px;
    }
  }
  &__subtitle {
    @include breakpoint($small-down) {
      font-size: 12px;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
  }
  &__sidebar {
    @include breakpoint($medium-up) {
      .viewcart & {
        width: $checkout-sidebar-width; // override the viewcart width, coming from bb_base, widening it for the UK (per the mocks given)
      }
    }

    // tabbing system (pulled over from checkout.css, on BrZ UK1)
    .tab-switcher {
      @include checkout-sidebar-panel-border;
      padding: 0;
      @include breakpoint($small-down) {
        display: none;
      }
    }
    .tab-bar {
      @include clearfix;
      @include checkout-sidebar-panel;
      padding: 0;
      li {
        @include clearfix;
        display: block;
        float: left;
        background-color: $color-gray-light-alt;
        width: 33%;
        text-align: center;
      }
    }
    .tab {
      display: block;
      width: 100%;
      text-decoration: none;
      color: $color-black;
      @include breakpoint($medium-up) {
        padding: 10px 5px;
        @include h22;
      }
      @include breakpoint($large-up) {
        @include h12;
        padding: 10px;
      }
      &.active {
        background-color: white;
      }
    }
    .tab-content {
      padding: 15px;
    }
    .tab-pane {
      opacity: 0;
      height: 0;
      overflow: hidden;
      -webkit-transition: opacity 1s ease;
      -moz-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
        footer {
          display: none;
          text-align: right;
        }
      }
      header,
      footer {
        display: none;
      }
    }
    // end tabbing system

    // stuff for the checkout sidebar samples panel, the product rows
    .product {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $color-gray;
      &:nth-child(2) {
        margin-top: 0;
        border-top: none;
      }
      @include breakpoint($small-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .checkout__panel-title {
      @include breakpoint($small-down) {
        text-align: left;
      }
    }
    .panel {
      @include breakpoint($small-down) {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .product-img {
      max-width: 80px; // to match the small image size, which we're using for product images (regular and samples)
      margin: 0 10px 0 0;
      float: left;
    }
    .details {
      margin-top: 0;
      width: 69%;
      display: inline-block;
    }

    // hide the samples panel's add and no thanks buttons when in the sidebar (not the popup)
    .add-button,
    .no-thanks {
      display: none;
    }
    .remaining,
    .remaining p {
      margin-bottom: 0;
      .samples-panel__heading {
        display: none;
      }
      .samples-panel__promo-text {
        display: inline-block;
        &.large {
          display: none;
        }
        @include breakpoint($large-up) {
          display: none;
          &.large {
            display: inline-block;
          }
        }
      }
    }
    .links-panel {
      .content {
        ul.links_list {
          li a {
            border-width: 0px 0px 2px 0px;
          }
        }
      }
    }
    .offer-code-panel {
      margin-top: 6px;
      .content {
        p {
          margin: 0px 0px 15px 0px;
        }
        #offer_code {
          margin-bottom: 15px;
          .btn[type='submit'] {
            height: 31px;
            vertical-align: top;
          }
        }
      }
    }
  }
  &__new-account,
  &__return-user {
  }
  a.btn.edit {
    top: 10px;
    padding: 0.5em 1em;
    @include breakpoint($small-down) {
      padding: 0.35em 0.75em;
      font-size: 12px;
      right: 7px;
    }
  }
  #gift-options #shipping-panel-edit {
    position: relative;
    margin-left: 40px;
    @include breakpoint($small-down) {
      margin-bottom: 15px;
      margin-left: 20px;
      width: 57%;
    }
  }
  #gift-options #gift_wrap_text #shipping-panel-edit {
    margin-left: 0px;
  }
  .gift-options-display {
    p {
      word-wrap: break-word;
    }
    @include breakpoint($small-down) {
      #gift-options-h {
        width: 100%;
      }
      #shipping-panel-edit {
        margin-bottom: 20px !important;
        margin-left: 8px !important;
        width: 80% !important;
        padding: 10px;
      }
    }
  }
}

.order-summary {
  &-panel {
    .checkout__sidebar & {
      header {
        @include breakpoint($small-down) {
          display: block;
        }
      }
      .content {
        @include breakpoint($small-down) {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
      .label {
        @include breakpoint($small-down) {
          width: 75%;
        }
        font-family: $brandon-text;
      }
      .value {
        @include breakpoint($small-down) {
          width: 25%;
        }
      }
    }
    .checkout__content & {
      header {
        //@include breakpoint($small-down) {
        display: block;
        //}
        h2 {
          padding-right: 20px;
        }
      }
      @include breakpoint($small-down) {
        border-top: none;
        padding-top: 0px;
      }
    }
    .total {
      font-family: $brandon-text-bold;
    }
    .value {
      padding-right: 20px;
      line-height: 19px;
    }
  }
  &__total-label,
  &__total-value {
    border-top: none;
  }
}

.need-help-panel {
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      @include checkout-sidebar-panel;
      @include checkout-sidebar-panel-border;
    }
  }
  .additional-info {
    margin: 0;
    padding: 0;
    //line-height: normal;
  }
  &__title {
    @include breakpoint($large-up) {
      // specificity
      .checkout__sidebar & {
        @include breakpoint(text--short);
        //border-bottom: none;
      }
    }
    .checkout__sidebar & {
      margin-top: 0;
    }
  }
}

.need-help-panel {
  @include breakpoint($medium-up) {
    h4 {
      margin-bottom: 8px;
      letter-spacing: 0.24em;
      * {
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        color: $color-pink;
        font-size: 14px;
        line-height: 19px;
        vertical-align: top;
        padding-bottom: 5px;
      }
      a {
        @include link($color-pink, $color-black);
      }
    }
  }
  @include breakpoint($small-down) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .content {
      h4 {
        a {
          border-width: 0px 0px 2px 0px;
          padding-top: 0px;
        }
      }
    }
  }
}

.links-panel {
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      @include checkout-sidebar-panel;
      @include checkout-sidebar-panel-border;
    }
  }
  .content {
    @media screen and (min-width: 768px) {
      .links_list {
        li {
          border: none;
          .overlay-link {
            border-width: 0px 0px 2px 0px !important;
          }
        }
      }
    }
    @include breakpoint($small-down) {
      margin-left: 15px;
      margin-right: 20px;
    }
    .link {
      @include breakpoint($medium-up) {
        border-bottom: none;
        margin-bottom: 5px;
        a {
          @include link($color-pink, $color-black);
        }
      }
      @include breakpoint($small-down) {
        border: none;
        text-transform: none;
        font-family: $brandon-text;
        letter-spacing: 1px;
        display: block;
        padding: 10px 0 0;
      }
    }
  }
  &__title {
    .checkout__sidebar & {
      margin-top: 0;
    }
  }
}

.shipmethod {
  &-panel {
    padding-top: 14px;
    .checkout__sidebar & {
      @include breakpoint($medium-up) {
        @include checkout-sidebar-panel;
      }
    }
    @include breakpoint($small-down) {
      .content {
        padding: 0 10px 20px;
      }
    }
    .ship-method-group {
      &-label {
        @include breakpoint($medium-up) {
          text-transform: uppercase;
        }
        padding-bottom: 5px;
        @include breakpoint($small-down) {
          float: left;
          padding-right: 20px;
        }
      }
      & .selectbox {
        margin: 5px 0;
      }
      &,
      & label,
      &-label label,
      & .selectbox {
        @include breakpoint($small-down) {
          font-size: 10px;
        }
      }
    }
    .date22-form-item {
      @include breakpoint($medium-up) {
        width: 48%;
      }
    }
    .ship-method-collection-group {
      @include breakpoint($small-down) {
        clear: both;
      }
    }
  }
}

.offer-code {
  &-panel {
    .content {
      input[type='text'] {
        width: 60%;
      }
      input.btn[type='submit'] {
        width: 35%;
        height: 45px;
      }
      @include breakpoint($small-down) {
        padding-left: 10px;
        padding-right: 10px;
        p {
          margin-bottom: 15px;
        }
        form {
          position: relative;
        }
        input[type='text'] {
          width: 55%;
        }
        input.btn[type='submit'] {
          width: 40%;
          position: absolute;
          right: 0;
        }
        .note {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.recommended {
  &-products {
    &-panel {
      border-top: 1px solid $color-gray;
      margin-top: 20px;
      padding-top: 20px;
      &__title {
        padding-bottom: 10px;
      }
      .content {
        padding: 0 20px;
      }
    }
  }
  &-product {
    &-items {
      border-top: 1px solid $color-black;
      padding-top: 20px;
    }
  }
  &-item {
    border-right: 1px solid $color-gray;
    padding: 0 20px;
    &:last-child {
      border-right: none;
    }
  }
}

.gift-message {
  &-h {
    &__title {
      display: block;
      margin-top: 25px;
    }
  }
}

.registration {
  &-panel {
    &__title {
      @include checkout-section-header;
    }
  }
}

.review {
  &-edit {
    &-content {
      position: relative;
    }
  }
  &-panel {
    .field-are-required {
      top: 0;
      right: 0;
      font-size: 11px;
    }
    .gift-cards {
      margin-bottom: 20px;
    }
    .payment-type {
      margin-top: 20px;
    }
    .payment-option {
      .related-media {
        display: block;
        @include breakpoint($small-down) {
          img {
            width: 25px;
          }
        }
        @include breakpoint($medium-up) {
          margin-top: 12px;
        }
      }
    }
  }
}

// pieces borrowed from ~/perlgem/sites/brz/uk1/css/pc/conditional/checkout.css,
// pieces that are "functional" in nature, in that certain pieces of functionality
// don't work without them.
#checkout-header {
  #mobile-cart-button {
    display: none;
    &.inactive:before {
      content: '+ ';
    }
    &.active:before {
      content: '- ';
    }
    @media (max-width: 768px) {
      display: inline-block;
    }
  }
  ul.nav-links {
    float: right;
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 1em;
    }
    .checkout-text {
      display: none;
    }
  }
  .site-logo {
    display: block; // override the "responsive" style, that hides the logo when sizing below a certain size
  }
}

#mobile-breadcrumb {
  display: none;
  list-style-type: none;
  margin: 0.5em 0;
  li {
    display: inline-block;
  }
  @media (max-width: 768px) {
    body.active-panel-review & {
      display: block;
    }
  }
}

.guarantee-panel {
  @include breakpoint($small-down) {
    padding: 0;
    background-color: $color-nude !important;
    margin-top: 20px;
    margin-bottom: 20px;
    &__title {
      padding: 15px 0;
      &.checkout__panel-title {
        border-bottom: 1px solid #000 !important;
        margin: 0 10px 10px 15px;
      }
    }
    .content {
      margin-left: 15px;
      margin-right: 10px;
    }
  }
  .checkout__content & {
    @include breakpoint($small-down) {
      display: none;
    }
  }
  .checkout__sidebar & {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

@import 'checkout-cart-item';
@import 'checkout-panel-account';
@import 'checkout-panel-shipping';
@import 'checkout-panel-review';
@import 'checkout-samples';
@import 'checkout-confirmation';
@import 'checkout-offers-promos';
@import 'checkout-viewcart';
@import 'checkout-viewcart-summary';
@import 'checkout-viewcart-samples';
@import 'checkout-click-and-collect-delivery';

.touch .form-item.expiry select,
.form_element.expiry select {
  min-width: 0;
}

.touch {
  select {
    min-width: 100%;
  }
}
/* Donations */
div.column section.donations.panel {
  div.donation_info_panel {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    @include breakpoint($small-down) {
      margin-left: 0px;
    }
  }
  div.donation_logo {
    float: left;
    height: 100%;
    padding-right: 20px;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  div.donation_logo_mobile {
    float: left;
    height: 100%;
    padding-right: 20px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  div.donation_message {
    margin-left: 140px;
    @include breakpoint($small-down) {
      margin-left: 130px;
      display: none;
    }
  }
  div.donation_message_mobile {
    margin-left: 125px;
    font-size: 12px;
    @include breakpoint($medium-up) {
      margin-left: 130px;
      display: none;
    }
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
    letter-spacing: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none !important;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
  }
  label.onoffswitch-label:before {
    display: none !important;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 13px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .onoffswitch-inner:after {
    content: 'Slide right to donate \00A3 1';
    padding-right: 10px;
    color: #838279;
    text-shadow: 1px 1px #ffffff;
    background: #c0c0c1; /* Old browsers */
    background: -moz-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #c0c0c1),
      color-stop(26%, #ffffff),
      color-stop(100%, #e9e9ea)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* IE10+ */
    background: linear-gradient(to bottom, #c0c0c1 0%, #e9e9ea 26%, #ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0c0c1', endColorstr='#e9e9ea',GradientType=0 ); /* IE6-9 */
    text-align: right;
  }
  .onoffswitch-inner:before {
    content: 'Thank you for donating!';
    padding-left: 5px;
    text-shadow: 1px 1px #559bb4;
    color: #ffffff;
    text-align: left;
    background: #5eaec2; /* Old browsers */
    background: -moz-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5eaec2),
      color-stop(26%, #6fcde4),
      color-stop(100%, #6fcde4)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5eaec2 0%, #6fcde4 26%, #6fcde4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5eaec2', endColorstr='#6fcde4',GradientType=0 ); /* IE6-9 */
  }
  .onoffswitch-switch {
    display: block;
    width: 60px;
    margin: 0px;
    height: 26px;
    background: #5680ac; /* Old browsers */
    background: -moz-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5680ac),
      color-stop(100%, #1e3a5c)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #5680ac 0%, #1e3a5c 100%); /* IE10+ */
    background: linear-gradient(to bottom, #5680ac 0%, #1e3a5c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5680ac', endColorstr='#1e3a5c',GradientType=0 ); /* IE6-9 */
    border: 2px solid #5a7ea9;
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox.checked ~ .onoffswitch-label .onoffswitch-switch {
    left: 158px;
  }
  div.form_element.onoffswitch {
    position: relative;
    width: 222px;
  }
  @include breakpoint($medium-up) {
    #charity-header {
      padding-left: 20px;
    }
  }
  @media (max-width: 768px) {
    div.content {
      padding: 0px 10px;
    }
    div.donation_amounts_panel {
      // Margins for donation_amount_panel have to be lower than it's parent, to be aligned properly on mobile
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

div.donation_amounts_panel {
  #select-amount {
    float: left;
    @include breakpoint($medium-up) {
      padding-left: 20px;
    }
    @include breakpoint($small-down) {
      display: none;
    }
  }
  #select-amount-mobile {
    float: left;
    @include breakpoint($small-down) {
      padding-top: 2px;
      font-size: 9px;
      //font-size: 2vw;
    }
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  label {
    font-family: $brandon-text !important;
    padding-left: 5px;
    @media only screen and (max-device-width: 470px) {
      padding-left: 2px;
      font-size: 10px;
    }
  }
  .donation-group {
    @media only screen and (max-device-width: 359px) {
      white-space: nowrap;
    }
  }
  h2 {
    // background-color: #E0F3F9 !important;
    background-color: $color-gray-light-alt !important;
    @include breakpoint($small-down) {
      letter-spacing: 0.1em !important;
      text-align: right !important;
    }
    @media only screen and (max-device-width: 359px) {
      overflow: auto;
      text-align: left !important;
    }
  }
}

#donation-order-summary {
  @include breakpoint($small-down) {
    text-transform: capitalize;
  }
}

.checkout__sidebar {
  section.donations {
    padding: 0px 19px 13px 19px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }
  .product {
    padding-top: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 979px) {
  .recommended-product-items .recommended-item .product_name a {
    border-style: none !important;
    border-width: 0px !important;
    padding: 0px !important;
    text-decoration: none;
    word-break: break-word !important;
  }
}

@media (min-width: 1025px) {
  .checkout__sidebar {
    .tab {
      padding: 10px 6px;
      font-size: 10px;
    }
  }
}

#terms-conditions h2 {
  text-transform: uppercase;
  font-weight: bold;
}

.checkout {
  .checkout__sidebar {
    .delivery_options {
      &__content {
        background: #eff0f0;
        margin-top: 5px;
        padding: 10px 10px 10px 10px;
        p {
          margin: 20px 0px 20px 0px;
        }
      }
    }
  }
  #new-account,
  #return-user {
    padding: 0 20px 25px 20px;
  }
  .sign-in-panel #checkout_signin-submit {
    @include breakpoint($medium-up) {
      margin-left: 20px;
    }
  }
}

.checkout {
  .checkout__content {
    .new-address {
      .form-item,
      .form_element {
        width: 49%;
        display: inline-block;
        &.title {
          display: none;
        }
        &.last-name,
        &.first-name {
          float: none;
        }
        &.postal_code_container {
          display: block;
          width: 100% !important;
          input {
            width: 48.5%;
            @include breakpoint($small-down) {
              width: 48.5%;
            }
          }
          span.lookup_submit_note {
            position: relative;
            top: -38px;
            display: block;
            left: 0px;
            @include breakpoint($small-down) {
              width: 100%;
              margin-left: 0;
              top: 0px;
            }
          }
          span.fieldset-note {
            left: 275px;
            @media (min-width: 970px) {
              left: 305px;
            }
            position: relative;
            top: -55px;
            margin-left: 10px;
            @include breakpoint($small-down) {
              margin-left: 5px;
              left: 0px;
              top: 0px;
            }
            .address_lookup_submit {
              padding: 15px;
              position: absolute;
              width: 150px;
              font-size: 12px;
              top: -10px;
              @include breakpoint($small-down) {
                display: inline;
                vertical-align: middle;
                margin: 0;
              }
            }
          }
          .lookup_results_container {
            width: 90%;
          }
        }
        &.city_container {
          position: relative;
        }
        &.state_container {
          margin-bottom: 20px;
        }
      }
      .edit-shipping-radio,
      .edit-billing-radio {
        clear: left;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .shipping-address-display {
        display: none;
      }
    }
    @include breakpoint($small-down) {
      .bogo-offer {
        .cart-item__qty-label {
          float: none;
          display: inline-block;
        }
      }
    }
    input,
    select {
      border-color: #000000;
      height: 45px;
    }
    input.error,
    select.error,
    a.selectBox.error,
    a.selectbox.error {
      border-color: #ff0000;
    }
    .sign-in-panel {
      .content {
        padding: 20px;
        @include breakpoint($small-down) {
          padding: 20px 10px 5px 10px;
          input[type='text'] + label,
          input[type='password'] + label {
            margin-top: calc(-4em - 4px);
          }
        }
      }
    }
    #address_form_container {
      border: 0;
      padding: 0;
    }
    #checkout_shipping_panel {
      margin: 0;
    }
    .payment-panel {
      @include payment-panel;
    }
    input[type='text'] + label,
    input[type='tel'] + label {
      line-height: 0;
      position: absolute;
    }
  }
  .gift-options .form-item {
    position: inherit;
  }
  .panel label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  #recommended-products-panel {
    h2.checkout__panel-title {
      text-align: left;
    }
    h2.checkout__panel-title:before,
    .checkout__panel-title:after {
      border-top: none;
      width: 5px;
      margin: 0 0.4em 0 0.8em;
    }
    .content {
      .recommended-product-items {
        .recommended-item {
          width: 30%;
          .description {
            margin-top: 15px;
            .info {
              .formatted_price {
                border: none;
                text-align: left;
              }
              div {
                padding: 0.4em 0;
              }
            }
          }
          .quickshop {
            a.recommended-item__button {
              color: #fc5669;
              background-color: #fff;
              padding: 0px 0px 10px 0px;
              text-align: left;
              letter-spacing: 1px;
              font-size: 12px;
              border-bottom: 1px solid;
            }
          }
          .addtobag {
            margin-top: 10px;
            #cart {
              input.btn {
                background-color: #fff;
                color: #fc5669;
                font-size: 12px;
                letter-spacing: 1px;
                border-bottom: 1px solid;
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.engraving_option {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  background-color: $color-gray-background;
  .engraving-cart-control {
    margin: 10px;
  }
  .engraving-button-wrapper {
    float: right;
    padding: 30px 0px 0 10px;
  }
  .engraving-show-toggle.btn,
  .engraving-toggle-cancel.btn {
    padding: 0 6px;
    width: 150px;
    font-size: 11px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    border: solid 2px $color-black;
    letter-spacing: 0;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
    }
    &:hover {
      border: none;
      line-height: 44px;
    }
  }
  .engraving-toggle-cancel.btn {
    background-color: $color-gray-background;
    color: $color-black;
  }
}

.engraving-wrapper {
  background-color: $color-gray-background;
  padding: 1em;
  overflow: hidden;
  .engraving-form-element-content,
  .engraving-service-text {
    margin: 10px;
  }
  .monogram-error {
    margin-bottom: 10px;
  }
  .engeave-description {
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    input#monogram_custom_text {
      width: 50%;
      margin-bottom: 10px;
    }
    select.monogram_custom_image {
      width: 40%;
    }
  }
  @media (max-width: 768px) {
    input,
    select {
      margin: 10px auto;
    }
  }
  .engraving-label.engraving-selected {
    display: block;
    text-align: center;
  }
  .engraving-message-count {
    display: block;
    clear: both;
    margin-bottom: 20px;
  }
  .action-buttons-div {
    width: 100%;
    height: 40px;
    margin: 10px 0 20px 0;
  }
  .engraving-save.action-anchor {
    display: block;
    color: $color-black;
    border: 2px solid $color-black;
    float: none;
    padding: 12px;
    margin: auto;
    text-decoration: none;
    text-align: center;
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 30%;
    }
  }
  div.engraving-view {
    @media (min-width: 768px) {
      border-top: 2px solid #a9a9a9;
    }
    padding-top: 1em;
  }
  .engraving-view-title {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
  }
  .engraving-view-text-label,
  .engraving-view-motif-label {
    font-weight: bold;
  }
  .engraving-view-text-content,
  .engraving-view-motif-content {
    text-align: center;
    border: 1px solid #a9a9a9;
    cursor: pointer;
    font-size: 26px;
    height: 65px;
    background: $color-white;
    color: #a9a9a9;
    clear: both;
  }
  .engraving-view-text-content {
    width: 60%;
    .monogram_custom_text {
      line-height: 2.5;
    }
  }
  .engraving-view-motif-content {
    width: 30%;
    img {
      padding-top: 10px;
    }
  }
  .engraving-toggle-edit {
    display: block;
    margin-top: 10px;
  }
  .engraving-view-footer {
    margin: 0 10px;
  }
}

.index {
  .engraving_option {
    display: none;
  }
  .engraving-wrapper {
    width: 100%;
    padding: 0;
    .engraving-view-control,
    .engraving-view-footer {
      display: none;
    }
    div.engraving-view {
      border-bottom: 2px solid #a9a9a9;
    }
    .engraving-view-text,
    .engraving-view-motif {
      margin: 20px 0;
    }
  }
  .engraving-wrapper.mobile {
    div.engraving-view {
      padding-left: 10px;
    }
  }
}

.registration-panel .checkout__panel-content {
  padding: 0;
  .checkout-registration {
    .checkout-registration__email-address {
      margin-bottom: 10px;
    }
  }
  .continue-button-wrapper {
    margin-top: 10px;
  }
}

.samples-overlay #samples-panel div.offer ul.product-list {
  margin: 0;
}

.checkout__content {
  .adpl {
    input[type='email'] + label,
    input[type='tel'] + label,
    input[type='password'] + label,
    input[type='text'] + label,
    select + label {
      display: block;
      height: 3em;
      line-height: 3em;
      margin-top: calc(-3.8em - 12px);
      cursor: text;
      float: left;
    }
  }
  .phone_requirements {
    margin: 15px 0;
  }
}

.checkout .payment-edit-content {
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    @include breakpoint($small-down) {
      margin-bottom: 0;
    }
  }
}

.checkout__sidebar {
  @include breakpoint($small-down) {
    .links-panel,
    .need-help-panel {
      margin-top: 10px;
    }
  }
}

.order-summary-panel {
  .order-summary-panel__title {
    border-bottom: none;
  }
}

.order-summary__content {
  .order-summary {
    &__total-label,
    &__total-value {
      border-top: 1px solid #bbbbb9;
      border-bottom: none;
    }
  }
  .tax {
    line-height: 40px;
  }
  .giftwrap {
    &.label,
    &.value {
      display: none;
    }
  }
}

.order-details-page__content {
  .order-totals {
    .checkout__panel-title {
      border-bottom: none;
    }
    .total {
      border-top: 1px solid #000;
    }
    .discount {
      &.label {
        text-align: left;
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-device-width: 767px) {
  .viewcart,
  .samples,
  .index {
    header .mobile-checkout-back {
      display: block;
      i.icon-arrow_left {
        font-size: 39px;
        position: absolute;
        left: 12px;
        top: 10px;
      }
    }
  }
  .viewcart,
  .samples,
  .index,
  .confirm {
    position: static;
    section.checkout {
      position: static;
    }
    footer,
    header.site-header ul.site-utils__menu {
      display: none;
    }
    section#shipping-panel,
    section#payment-panel {
      margin-top: 20px;
      &.collapsed {
        display: none;
      }
    }
    .continue-buttons .continue-checkout-btns .continue-sticky,
    .continue-button-wrapper input[type='submit'].continue-sticky,
    #continue-sticky {
      bottom: 0px;
      position: fixed;
      line-height: 33px;
      height: 50px;
      left: 0px;
      width: 100%;
      text-align: center;
      font-size: 15px;
      z-index: 1;
      padding: 0;
    }
    #offer-code-panel__title {
      background: url(/media/images/global/icon-dropdown.png) no-repeat center right 10px #eff0f0;
    }
    #offer-code-panel__title.open {
      background: url(/media/images/global/icon-dragup.png) no-repeat center right 10px #eff0f0;
    }
    .opc__footer {
      padding-bottom: 60px;
      padding-top: 30px;
    }
    .checkout {
      .adpl {
        form#checkout_signin,
        #checkout_signin_new_user {
          .form-item {
            label {
              position: absolute;
            }
            input[type='password'] {
              margin-bottom: 13px;
            }
            input[type='email'] {
              margin-bottom: 19px;
              width: 100%;
            }
          }
        }
        .form-item,
        .form_element {
          padding-right: 3px;
          input[type='text'],
          input[type='password'],
          input[type='tel'],
          input[type='number'],
          input[type='email'] {
            margin-bottom: 18px;
          }
        }
        .postal_code_container,
        .phone1_container,
        .phone2_container,
        .card-number,
        .cvv {
          label {
            position: absolute;
          }
        }
        form#checkout_shipping_panel {
          .form-item,
          .form_element {
            margin-bottom: 0em;
          }
          .lookup_submit_note,
          .phone_requirements {
            margin: 0 0 10px 0;
          }
          .card-message-to,
          .card-message-from {
            width: 49%;
            display: inline-block;
          }
        }
      }
    }
  }
  .viewcart {
    .delivery-date-wrapper,
    .delivery-time-wrapper {
      p {
        margin: 0 0 10px 0;
      }
    }
  }
  .samples {
    section.viewcart-buttons-panel {
      display: none;
    }
    #continue-sticky {
      background-color: #ff4661;
      color: #ffffff;
      text-decoration: none;
      letter-spacing: 2px;
      padding-top: 5px;
      line-height: 40px;
    }
    #checkout_samples {
      .sample-select-button {
        background-color: #ff4661;
        &.selected {
          background: #000000;
        }
      }
    }
  }
  .index {
    &.active-panel-shipping {
      #mobile-breadcrumb {
        display: none;
      }
      #registration-panel {
        display: none;
      }
    }
    &.active-panel-registration {
      .links-panel {
        display: none;
      }
    }
    #new-account,
    #return-user {
      p.required {
        margin: 15px 0;
      }
    }
    form#checkout_signin_new_user,
    #checkout_signin {
      input[type='email'] + label:before,
      input[type='tel'] + label:before,
      input[type='password'] + label:before,
      input[type='text'] + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
    p.delivery-options {
      margin: 0 0 20px 0;
    }
    .registration-panel__title {
      margin-top: 15px;
    }
  }
  #confirmation-page h2,
  .order-details-page__header {
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .checkout {
    .adpl {
      form#checkout_signin,
      #checkout_signin_new_user {
        .form-item {
          label {
            position: absolute;
          }
          input[type='text'],
          input[type='password'],
          input[type='tel'],
          input[type='number'],
          input[type='email'] {
            margin-bottom: 15px;
            width: 100%;
          }
        }
      }
      #checkout_signin_new_user {
        input#checkout_signin_new_user-submit {
          top: 245px;
        }
      }
      form#checkout_signin {
        label {
          padding-left: 8px;
        }
      }
      form#checkout_payment {
        .card-number,
        .cvv {
          label {
            position: absolute;
          }
        }
      }
      form#checkout_shipping_panel {
        .billing-address-from-fields {
          margin-top: 15px;
        }
      }
      .form-item,
      .form_element {
        padding-right: 3px;
        input[type='text'],
        input[type='password'],
        input[type='tel'],
        input[type='number'],
        input[type='email'] {
          margin-bottom: 22px;
        }
      }
      .postal_code_container,
      .phone1_container,
      .phone2_container {
        label {
          position: absolute;
        }
      }
    }
    #confirmation-panel {
      h2 {
        line-height: 1.2em;
      }
    }
  }
  .order-details-page__content {
    padding-top: 10px;
  }
}

.adpl {
  form#checkout_registration_panel {
    .checkout-registration__password {
      .label-class {
        display: none !important;
      }
      label {
        position: absolute;
        pointer-events: none;
      }
      @media (min-width: 768px) {
        .password {
          width: 48%;
          display: inline-block;
          margin-right: 15px;
        }
        .text {
          width: 48%;
          display: inline-block;
        }
      }
    }
    @media (min-width: 768px) {
      .password-hint {
        .security_question {
          .label-class {
            margin-bottom: 5px;
          }
          select,
          a.selectBox {
            width: 48%;
            float: left;
            min-width: unset;
          }
        }
        .security_answer {
          width: 48%;
          float: right;
        }
      }
      h3.checkout__subtitle {
        clear: both;
      }
    }
  }
  form#checkout_shipping_panel {
    .state_container {
      .label-content {
        display: block;
      }
    }
    .billing-address-from-fields {
      margin-top: 15px;
    }
  }
  section.shipping-add-address-content__content {
    margin: 15px 0 0 0;
  }
  .checkout__return-user {
    input[type='email'] {
      &.js-label-mode + label:before {
        content: attr(data-required) attr(alt);
      }
      + label:before {
        content: attr(data-required) attr(placeholder);
      }
    }
  }
}

@media (max-width: 767px) {
  .order-confirmation {
    .cart-item__thumb {
      height: auto;
    }
  }
  .order-details {
    .cart-item__thumb {
      height: auto;
    }
  }
}
.birth-month-container {
  .birthday-panel {
    &__title {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .select-birth-month {
    height: 3em;
    margin: 15px 0;
    width: 98%;
    @include breakpoint($medium-up) {
      width: 48%;
    }
  }
  .checkout-birthday {
    &__descriptive-text {
      padding-top: 10px;
    }
  }
  input[type='submit'] {
    &.btn {
      margin-bottom: 20px;
    }
  }
}
