$disable-bbv2-in-checkout: false !default;
$selector-to-disable: "body";

@if $disable-bbv2-in-checkout == true {
  $selector-to-disable: "body:not(.device-mobile):not(.pg-checkout), body.device-mobile > :not(.pg-checkout)";
}
.site-footer {
  .site-footer__main {
    border-bottom: 1px solid #cccccc;
    position: relative;
    .locale-select {
      margin-left: 20px;
    }
    .site-footer__column {
      &:nth-child(4) {
        position: absolute;
        bottom: 15px;
        right: 0px;
        padding: 10px 20px 0 20px;
      }
      &:nth-child(2) {
        .menu__item {
          width: 100%;
          float: none;
        }
      }
    }
    .trustmark-logo {
      width: 75px;
    }
  }
  &__country-chooser {
    .country-select__selectbox {
      .touch &,
      .touchevents & {
        min-width: 0;
      }
    }
  }
}

.lp_radio_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  .site-footer-contact__item--email {
    float: left;
    width: 100%;
    padding: 20px;
    a {
      border-right: 0px !important;
      span {
        display: inline !important;
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .site-footer-contact__item {
    width: 50%;
    float: left;
  }
  .site-footer-contact__item--email {
    padding: 20px;
    a {
      border-right: 0px !important;
      padding: 0px !important;
      span {
        display: inline !important;
      }
    }
  }
  .site-footer-contact__item--phone {
    border-bottom: 0px;
    border-right: 1px solid #cccccc;
  }
}

.section-store-locator {
  .responsive-tabs--store-locator {
    .resp-tab-item:last-child {
      display: none;
    }
  }
  .store-locator__search {
    padding-bottom: 45px;
    select.distance {
      display: block !important;
      visibility: visible;
      position: absolute;
      width: 100%;
      top: 45px;
    }
  }
}

.site-footer__wrap > .block-template-basic-responsive-v1 {
  padding: 0 20px;
}

.error {
  color: #ff0000;
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      @media screen and (orientation: portrait) {
        padding: 1em 5px;
        letter-spacing: 0px;
      }
    }
    #category-other {
      @media only screen and (max-width: 767px) {
        padding: 25px 5px;
      }
    }
    .customer-service-contact-form__input--registered {
      display: block;
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: auto;
      }
    }
  }
}

.coremetrics-opt-wrapper {
  padding: 10px;
  position: relative;
  td {
    border: none;
    width: 100%;
  }
  .view-opt-out-status {
    margin: 25px 0px 10px 0px;
  }
  .site-logo__icon {
    font-size: 30px;
  }
}

.offers-hero__image-container {
  @media (max-width: 767px) {
    display: block;
  }
}
/* --------- POWER REVIEWS--------- */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: '役に立った';
              font-size: 12px;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: '役に立たなかった';
              font-size: 12px;
            }
          }
        }
      }
      @include breakpoint(($small-down) (orientation portrait)) {
        .pr-rd-footer {
          .pr-rd-helpful-action {
            margin-bottom: 0;
            .pr-helpful-btn {
              float: none;
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:after {
                  display: none;
                }
              }
            }
          }
          .pr-rd-flag-review-container {
            a {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
    input.error {
      border: 1px solid red;
    }
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-size: 15px;
}

.product__notify-me {
  padding-top: 5px;
}

.forgot_pwd .js-forgot-password {
  display: none;
}

.forgot_pwd .forgot_pwd_text .js-forgot-password {
  display: inline-block;
}

#viewcart-panel {
  .cart-item {
    &__replenishment {
      .selectbox {
        background-position: 301px 14px;
      }
    }
    &__qty {
      select {
        background-position: 36px 16px;
      }
    }
  }
}

#checkout_delivery {
  select[name='DELIVERY_DATE'],
  select[name='DELIVERY_TIME'] {
    background-position-x: 95%;
    background-position-y: 15px;
  }
}

#{$selector-to-disable} {
  .site-footer {
    &__column-title,
    &-social-links__header {
      font-size: 1.15em !important;
      font-weight: 700;
    }
  }
}

.site-header__menu {
  .gnav-section {
    .gnav-section__link--top {
      @include breakpoint($small-down) {
        font-size: 20px;
        padding: 16px 0;
      }
    }
  }
}

.product-brief {
  .product-brief__bottom-mobile {
    .product-brief {
      &__short-desc-link {
        width: 31%;
      }
      &__add-to-bag {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.section-wonder-wheel {
  .game {
    &--spinner {
      .game-overlay {
        &__section {
          &--terms {
            .game-overlay {
              &__text {
                @include breakpoint($small-down) {
                  font-size: 0.65em;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-foundation-vto {
  &__info-container {
    margin-bottom: -5px;
    .product-details-container {
      .product-name {
        display: none;
      }
      .product-name-subline {
        display: block !important;
        width: 69%;
      }
    }
    .product-full {
      &__size {
        display: none;
      }
    }
  }
  &__undertone-title {
    font-size: 30px;
  }
  &__undertone-header {
    font-size: 14px;
  }
  &__undertone-copy {
    font-size: 13px;
  }
  &__undertone-importance-copy {
    font-size: 13px;
  }
  &__overlay-title {
    font-size: 2em;
    line-height: 1.35;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  &__overlay-copy {
    width: 70%;
  }
}

.field-collection-slides {
  .snow-globe {
    .game-popup-box {
      .win-info {
        .game-win-title {
          p {
            font-size: 35px;
            font-weight: 500;
            margin: 0 0.6em;
          }
        }
        .game-win-description {
          font-size: 17px;
        }
      }
    }
    .game-landing {
      .game-title {
        font-size: 38px;
        font-weight: 400;
      }
      .game-subtitle {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
