.cart-item {
  &__desc-content {
    @include breakpoint($small-down) {
      @include clearfix;
      float: left;
      .checkout__sidebar & {
        width: 100%;
      }
    }
  }
  &__desc {
    &-info {
      width: 95%;
      .color {
        @include breakpoint($small-down) {
          margin: 4px 0 0;
        }
      }
      .product_name,
      .size,
      .sub_line {
        width: 100%;
      }
    }
  }
  &__desc .remove-sample,
  &__remove-form a {
    @include breakpoint($small-down) {
      font-size: 11px;
    }
  }
} // /cart-item

.cart-item {
  .checkout__sidebar & {
    border-bottom: none;
    padding-bottom: 0;
    .cart-item__total {
      @include breakpoint($small-down) {
        position: static;
        float: right;
        width: 30%;
        margin-right: 10px;
        margin-top: 8px;
      }
    }
    .cart-item__desc-info {
      width: 60%;
    }
  }
  &__thumb {
    @include breakpoint($small-down) {
      height: 100px;
    }
    .checkout__content & {
      @include breakpoint($medium-up) {
        &-image {
          padding: 0;
        }
      }
    }
  }
  &__qty {
    .sample & {
      @include breakpoint($medium-up) {
        .checkout__content & {
          padding-left: 12px;
        }
      }
      .cart-item__qty-label {
        float: none;
      }
    }
    @include breakpoint($small-down) {
      margin-top: 0;
      padding-top: 0;
      &-label,
      & .qty-label {
        .checkout__sidebar & {
        }
      }
    }
    .checkout__sidebar & {
      @include breakpoint($small-down) {
        float: left;
        padding-top: 0;
        text-align: left;
        clear: $ldirection;
        position: relative;
        #{$ldirection}: 29%;
      }
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
    select,
    a.selectBox,
    .selectbox & {
      @include breakpoint($small-down) {
        min-width: 50px;
        width: 50px;
        margin-top: 5px;
      }
    }
    .checkout__sidebar & * {
      display: inline-block;
    }
    a {
      color: $color-black;
      border-bottom: 1px solid $color-black;
    }
  }
  &__price {
    .price_label {
      display: none;
    }
    .checkout__sidebar & {
      display: none !important;
    }
  }
  &__desc,
  &__qty,
  &__total {
    float: left;
    clear: none;
  }
  &__size,
  &__total,
  &__total a,
  &__desc-info .shade,
  & .color {
    @include breakpoint($small-down) {
      font-size: 14px;
    }
  }
}

.viewcart {
  .cart-item {
    &__total {
      @include breakpoint($small-down) {
        font-family: 'Brandon Text';
        text-align: right;
      }
    }
    &__desc {
      width: 34%;
    }
    @include breakpoint($small-down) {
      &__desc {
        width: 60%;
        .shade {
          margin-left: 0px;
        }
      }
    }
    &__price {
      width: 25%;
      display: block !important;
      float: right;
    }
    &__total {
      width: 16% !important;
    }
    &__qty {
      float: right;
      width: 32%;
    }
    @include breakpoint($medium-up) {
      &__qty {
        width: 12% !important;
        float: left;
      }
      &__price {
        width: 19%;
        float: left;
      }
      &__total {
        width: 18% !important;
        padding-#{$ldirection}: 10px;
      }
    }
  }
}

.linethrough {
  text-decoration: line-through;
}

.viewcart-panel.edit .price_sm {
  display: none;
}

.cart-item__remove-form {
  .remove_link {
    width: auto;
  }
}
