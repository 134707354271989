// cart items header
.cart-header {
  font-family: $brandon-text-bold;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 0;
  //border-top: $cart-line;
  border-bottom: $cart-line;
  width: 100%;
  margin-top: $cart-spacing;
  @include mobile_hidden;
  &__item {
    float: left;
  }
  &-sub-container {
    float: left;
    width: $cart-item-container-width;
  }
  &-spacer {
    width: $cart-item-desc-width;
  }
  .products {
    width: 50%;
    padding-left: 20px;
    float: left;
  }
  .price {
    width: 20%;
  }
  .qty {
    width: 20%;
  }
  .total {
    width: 8%;
    text-align: right;
  }
  .column {
    float: left;
  }
  // sidebar
  .checkout__sidebar & {
    display: none !important;
  }
}
